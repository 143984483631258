//vue-router
import Vue from "vue";
import Router from "vue-router";
const originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

export const constantRoutes = [
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    component: () => import("@/views/home/index"),
    hidden: true,
    redirect: "/home/newsview",
    children: [
      {
        path: "/home/newsview",
        name: "newsview",
        component: () => import("@/views/home/newsview/index"),
      },
      {
        path: "/home/questionnaire",
        name: "questionnaire",
        component: () => import("@/views/home/questionnaire/index"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
  },
  {
    path: "/userinfo",
    component: () => import("@/views/userinfo/index"),
    hidden: true,
  },
  {
    path: "/questionnaire",
    component: () => import("@/views/questionnaire/index"),
  },
  {
    path: "/transmit",
    component: () => import("@/views/transmit/transmit"),
  },
  {
    path: "/photoalbum",
    component: () => import("@/views/photoalbum/index"),
  },
  {
    path: "/lookOver",
    component: () => import("@/views/lookOver/lookOver"),
  },
  {
    path: "/questTemplate",
    name: "questTemplate",
    component: () => import("@/views/questTemplate/questTemplate"),
  },
  {
    path: "/templateDetails",
    name: "templateDetails",
    component: () => import("@/views/templateDetails/templateDetails"),
  },
  {
    path: "/newsdetail/",
    component: () => import("@/views/newsdetail/index"),
    name: "newsdetail",
  },
  {
    path: "/questions/reports/:id",
    component: () => import("@/views/transmit/report"),
    name: "newsdetail",
  },
];

const createRouter = () =>
  new Router({
    // mode: 'hash',
    // base: '',
    routes: [...constantRoutes],
  });

const router = createRouter();

//全局路由守卫配置
router.beforeEach((to, from, next) => {
  console.log(to.path);
  if (to.path != "/login" && to.path != '/newsdetail') {
    if (localStorage.getItem("token")) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
