//英文包
export default {
    main:{
      message:"message",
      display:"display",
      accountNumber:'account number',
      password:'Password',
      VerificationCode:'Verification Code',
      EditAccounta:'Edit Accounta',
      Thetwopasswordsentereddonotmatch:'The two passwords entered do not match',
      Confirmpasswordchange:'Confirm password change',
      enterusername:'Enter Username',
      enterusername1:'Enter Username',
      login:'Log In',
      signup:'Sign Up',
      tiao:'Strip',
      expand:'Expand',
      forgotPassword:'Forgot Password',
      retract:'Retract',
      notice:'Notice',
      questionnaireTemplate:'Questionnaire',
      Classname:'Class Name',
      ManageMembers:'Manage Members',
      AddMembers: 'Add Members',
      ShareAlbum:'Share Album',
      Questionnairerecords:'Questionnaire Records',
      Sender:'Sender',
      Allcontent:'All Content',
      Editprofile:'Edit Profile',
      Currentaccount:'Current Account',
      Loginpassword:'Login Password',
      setup:'Set Up',
      SetModify:'Set/Modify',
      Logout:'Log Out', 
      Uploadanewimage:'Upload a New Image',
      delete:'Delete',
      name:'Name',
      save:'Save',
      Setupanaccount:'Set Up An Account',
      cellphonenumber:'Cell-Phone Number',
      Sendverificationcode:'Send Verification Code',
      Verificationcodeerror:'Verification Code Error',
      nextstep:'Next Step',
      Pleaseenteranewphonenumber:'Please Enter a New Phone Number',
      confirm:'Confirm',
      Enterpasswordagain:'Enter Password Again',
      Confirmpasswordchange:'Confirm Password Change',
      Createanewgroup:'Create a New Group',
      Confirmcreation:'Confirm Creation',
      AddStudents:'Add Members',
      Continueaddingstudentnames:'Continue Adding Member Names',
      Addfromexistinglist:'Add From Existing List',
      Xgroupsselected:'X Groups Selected',
      PleaseclickonthetoprightcornerfirsttoactivatetheQRcodeinvitationfunction:'Please click on the top right corner first to activate the QRcode invitation function',
      AddMembers:'Add Members',
      Inviteyoutojoinus:'Invite you to join us',
      chan:'In the future, we can conduct home school communication, send notifications and questionnaires, share group albums, and other operations here',
      chan1:'Parents can scan QR codes and come to bind members or create new ones',
      chan2:'Use WeChat QR code, open the mini program, log in or register to join us',
      Downloadinvitationinterface:'Download invitation interface',
      ReplaceQRcode:'Replace QR code',
      chan3:'Do you want to close the QR code invitation? Others will not be able to enter by scanning the QR code',
      cancel:'Cancel',
      replace:'Replace',
      i18n_cancel:'取消',
      i18n_replace:'更换',
      chan4:'Are you sure that the QR code before replacing it will become invalid? Please operate with caution',
      chan5:'Scan the QR code directly to enter the mini program and bind members',
      DownloadQRcode:'Download QR code',
      Parentaccount:'Parent account',
      membername:'Member Name',
      Listofnewlycreatedchildren:'List of newly created children',
      claimgroups:'Claim Groups',
      ParentNotes:'Parent Notes',
      refuse:'Refuse',
      Confirmaddingthemember:'Confirm adding the member',
      Refusetoaddthismember:'Refuse to add this member',
      student:'Member',
      teacher:'Admin',
      Newmemberconfirmation:'New member confirmation',
      chan6:'The account must be a mobile phone number or email, otherwise the verification code will not be received',
      Loginaccount:'Login account',
      identity:'Identity',
      Addothermembersandaccounts:'Add other members and accounts',
      Areyousuretodeletethismember:'Are you sure to delete this member',
      administrators:'Administrators',
      AddTeacherAccount:'Add Admin Account',
      Pleaseenteranewphonenumber:'Please enter a new phone number',
      Pleaseinputapassword:'Please input a password',
      Teacheraccountediting:'Admin account editing',
      Setasadministrator:'Set as administrator',
      Removethismember:'Remove this member',
      Groupleaderhandover:'Group leader handover',
      Canceladministratoridentity:'Cancel administrator identity',
      chan7:'Whether to transfer the group leader s permissions',
      transfer:'Transfer',
      chan8:'Do you want to delete this label',
      chan9:'This label will disappear for all files, please operate with cautiona',
      chan10:'是否要切换成英文版/中文版',
      Refreshpageafterconfirmation:'确认后刷新页面',
      communicate:'Communicate',
      Enterachildsnametosearchfor:'Enter a child s name to search for',
      EditSendTo:'Edit Send To',
      Topping:'Topping',
      EditContent:'Edit Content',
      download:'Download',
      Externalsharing:'External sharing',
      export:'Export',
      CancelTopping:'Cancel Topping',
      Xmessagessentintotal:'messages sent in total',
      Email:'Email',
      Visibletoeveryone:'Visible to everyone',
      Somepeoplecansee:'Some people can see',
      QuestionnairePreview:'Questionnaire Preview',
      Option1:'Option 1',
      Usethistemplate:'Use this template',
      Labelmanagement:'Label management',
      Uploadimagesandvideos:'Upload',
      MultipleChoice:'Multiple Choice',
      chan11:'Press and hold Shift to select multiple options',
      Xselected:'selected',
      CancelMultipleSelection:'Cancel',
      goto:'Go To',
      label:'Label',
      chan12:'Sending questionnaires and notifications',
      TemplateLibrary:'Template Library',
      Sendingrecords:'Sending records',
      Reminderresponse:'Reminder response',
      Sendto:'Send to',
      withdraw:'Withdraw',
      ViewbyQuestion:'View by Question',
      chan13:'Withdraw and delete this send',
      chan14:'Reminders will be sent to XX members',
      chan15:'The data that has already been filled out will also be lost',
      Areyousuretowithdraw:'Are you sure to withdraw',
      yes:'Yes',
      no:'No',
      Areyousuretodeletethisnotification:'Are you sure to delete this notification',
      Pleaseoperatewithcaution:'Please operate with caution',
      close:'Close',
      Oneclickremindertoviewnotifications:'One click reminder to view notifications',
      Withdrawanddeletethissend:'Withdraw and delete this send',
      Createanewquestionnaire:'New questionnaire',
      Createanewnotification:'New notification',
      Chinese:'中',
      English:'English',
      essayquestions:'Essay questions',
      MultipleChoice1:'Exclusive Choice', 
      Multiplechoicequestions:'Multiple Choice',
      MoveUp:'Up',
      MoveDown:'Down',
      Save:'Save',
      Audiofiles:'Audio files',
      Recordingwithamicrophone:'Recording with a microphone',
      sendout:'Send',
      share:'Share',
      copy:'Copy',
      edit:'Edit',
      chan16:'The user was not found or is not a teacher or administrator',
      Sharingcompleted:'Sharing completed',
      gotit:'got it',
      Pleaseentercontent:'Please enter content',
      Pleaseselect:'Please select',
      questionnaire:'Questionnaire',
      Sharequestionnairewith:'Share with',
      SharingCompleted:'SharingCompleted',
      StudentAccountEditor:'Member Account Editor',
      Delete1:'Delete',
      SendContent:'Send Content',
      SendaQaH:'Send a questionnaire/notification to',
      SendQA:'Send a questionnaire',
      SendNotic:'Send a notification',
      CopySuccessfully:'Copy Successfully',
      HasBeenSet:'Has Been Set',
      NotSet:'Not Set',
      EnterYourPasswordAgain:'Enter Your Password Again',
      Passwordandsecondarypasswordcannotbeempty:'Password and secondary password cannot be empty',
      SendaMessage:'Send a Message',
      s:'s',
      PleaseEnterTheVerificationCode:'Please Enter The Verification Code',
      Folders:'Folders',
      GradeLevel:'Grade Level',
      ClassTheme:'Class Theme',
      ManageFolders:'Manage Folders',
      EditFolders:'Edit Folders',
      qsr:'Please enter the member name',
      Activateaccount:'Activate account',
      thatperson:'That person',
      editGroupVue:'Edit Group',
      icon:'Icon',
      photoSelectTitle1: "",
      photoSelectTitle2: "photos",
      color: 'Color',
      familyMemberAccount: 'Family members & Account',
      editStudents: 'Edit Member',
      account:'Account',
      isolatedMessage: 'Group members are invisible to each other',
      groupOwner: 'Group Owner',
      MessageCannotBeEmpty:'Message cannot be empty',
      mediaFileMaxLimit:'The maximum number of pictures has reached 40. Please upload a smaller quantity at a time',
      InvalidPhoneNumber:'Please enter the correct phone number',
      DeleteComment:'Do you want to delete this comment',


      tips: 'Tips',
      tipMessage1: 'This will delete the message permanently. Do you want to continue?',
      tipMessage2: 'Do you want to delete this comment?',
      tipMessage3: 'Please enter the share ID',
      tipMessage4: 'Are you sure you want to delete this survey?',
      tipMessage5: 'Whether to withdraw all members of the questionnaire?',
      tipMessage6: 'Successful operation',
      tipMessage7: 'Upload successfully',
      tipMessage8: 'Sent successfully',
      tipMessage9: 'Undeleted',
      failTxt: 'Translation failed. Please check the network and try again！',
      all: 'All'
    },
}