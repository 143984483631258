<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",

  mounted() {},
  updated() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
