//中文包
// zn.js

export default {
    main:{
      message:"消息",
      display:"展示",
      accountNumber:'账号',
      password:'密码',
      VerificationCode:'验证码',
      EditAccounta:'编辑账号',
      Thetwopasswordsentereddonotmatch:'两次输入密码不一致',
      Confirmpasswordchange:'确认修改密码',
      enterusername:'填写用户名',
      enterusername1:'输入用户名',
      login:'登录',
      signup:'注册',
      forgotPassword:'忘记密码',
      expand:'展开',
      retract:'收起',
      notice:'通知',
      questionnaireTemplate:'问卷模板',
      Classname:'班级名',
      ManageMembers:'管理成员',
      AddMembers: '添加成员',
      ShareAlbum:'共享相册',
      Questionnairerecords:'问卷记录',
      Sender:'发送人',
      Allcontent:'所有内容',
      Editprofile:'编辑个人资料',
      Currentaccount:'当前账号',
      Loginpassword:'登录密码',
      setup:'设置',
      SetModify:'设置/修改',
      Logout:'退出登录',
      Uploadanewimage:'上传新图片',
      delete:'删除',
      name:'姓名',
      save:'保存',
      Setupanaccount:'设置账号',
      cellphonenumber:'手机号',
      Sendverificationcode:'发送验证码',
      Verificationcodeerror:'验证码错误',
      nextstep:'下一步',
      Pleaseenteranewphonenumber:'请输入新的手机号',
      confirm:'确认',
      Enterpasswordagain:'再次输入密码',
      Confirmpasswordchange:'确认修改密码',
      Createanewgroup:'新建群组',
      Confirmcreation:'确认创建',
      AddStudents:'添加成员',
      Continueaddingstudentnames:'继续添加成员的名字',
      Addfromexistinglist:'从现有名单中添加',
      Xgroupsselected:'已选中X个群',
      PleaseclickonthetoprightcornerfirsttoactivatetheQRcodeinvitationfunction:'请先点击右上角开启QRcode 邀请功能',
      AddMembers:'添加成员',
      Inviteyoutojoinus:'邀请您加入我们',
      chan:'以后我们可以在此进行家校沟通，通知和问卷的发送，群组相册共享等操作',
      chan1:'家长扫描二维码，可以前来绑定成员，或者新建成员',
      chan2:'使用微信二维码，打开小程序后，登录或注册后即可加入我们',
      Downloadinvitationinterface:'下载邀请界面',
      ReplaceQRcode:'更换二维码',
      chan3:'是否关闭二维码邀请，其他人将无法通过扫描二维码能进入',
      cancel:'取消',
      replace:'更换',
      i18n_cancel:'Cancel',
      i18n_replace:'Replace',
      chan4:'是否确定更换二维码之前的二维码将失效，请谨慎操作',
      chan5:'直接扫描二维码进入小程序并绑定成员',
      DownloadQRcode:'下载二维码',
      Parentaccount:'家长账号',
      membername:'成员名',
      Listofnewlycreatedchildren:'新创建幼儿',
      claimgroups:'认领群内名单',
      ParentNotes:'家长备注',
      refuse:'拒绝',
      Confirmaddingthemember:'确认添加该成员',
      Refusetoaddthismember:'拒绝添加该成员',
      student:'成员',
      teacher:'管理员',
      Newmemberconfirmation:'新成员确认',
      chan6:'账号必须是手机号或者邮箱，不然收不到验证码',
      Loginaccount:'登录账号',
      identity:'身份',
      Addothermembersandaccounts:'添加其他成员和账号',
      Areyousuretodeletethismember:'是否确认删除此成员',
      administrators:'管理员',
      AddTeacherAccount:'添加管理员账号',
      Pleaseenteranewphonenumber:'请输入新的手机号',
      Pleaseinputapassword:'请输入密码',
      Teacheraccountediting:'管理员账号编辑',
      Setasadministrator:'设置为管理员',
      Removethismember:'移除该成员',
      Groupleaderhandover:'群主移交',
      Canceladministratoridentity:'取消管理员身份',
      chan7:'是否移交群主权限',
      transfer:'移交',
      chan8:'是否删除此标签',
      chan9:'所有文件的此标签将会消失，请谨慎操作',
      chan10:'Do you want to switch to English/Chinese version',
      Refreshpageafterconfirmation:'Refresh page after confirmation',
      communicate:'沟通',
      Enterachildsnametosearchfor:'输入幼儿名搜索',
      EditSendTo:'编辑发送对象',
      Topping:'置顶',
      EditContent:'编辑内容',
      download:'下载',
      Externalsharing:'外部分享',
      export:'导出',
      CancelTopping:'取消置顶',
      Xmessagessentintotal:'共发送',
      tiao:'条',
      Email:'邮箱',
      Visibletoeveryone:'所有人可见',
      Somepeoplecansee:'部分人可见',
      QuestionnairePreview:'问卷预览',
      Option1:'选项1',
      Usethistemplate:'使用该模板',
      Labelmanagement:'标签管理',
      Uploadimagesandvideos:'上传图片视频',
      MultipleChoice1:'单选题',
      chan11:'按住shift多选',
      Xselected:'已选中',
      CancelMultipleSelection:'取消多选',
      goto:'前往',
      label:'标签',
      chan12:'发送问卷和通知',
      TemplateLibrary:'模板库',
      Sendingrecords:'发送记录',
      Reminderresponse:'提醒回复',
      Sendto:'发送对象',
      withdraw:'撤回',
      ViewbyQuestion:'按问题查看',
      chan13:'撤回并删除此次发送',
      chan14:'将会给XX个成员发出提醒',
      chan15:'已经填写完的数据也将丢失',
      Areyousuretowithdraw:'是否确认撤回',
      yes:'是',
      no:'否',
      Areyousuretodeletethisnotification:'是否确定删除此通知',
      Pleaseoperatewithcaution:'请谨慎操作',
      Oneclickremindertoviewnotifications:'一键提醒查阅通知',
      Withdrawanddeletethissend:'撤回并删除此次发送',
      Createanewquestionnaire:'创建新问卷',
      Createanewnotification:'创建新通知',
      Chinese:'中',
      English:'English',
      essayquestions:'问答题',
      MultipleChoice:'多选',
      Multiplechoicequestions:'多选题',
      MoveUp:'上移',
      MoveDown:'下移',
      Save:'保存',
      Audiofiles:'音频文件',
      Recordingwithamicrophone:'使用麦克风录音',
      sendout:'发送',
      share:'分享',
      copy:'复制',
      edit:'编辑',
      chan16:'未找到该用户，或者此用户不是老师或管理员',
      Sharingcompleted:'分享已完成',
      gotit:'知道了',
      Pleaseentercontent:'请输入内容',
      Pleaseselect:'请选择',
      questionnaire:'问卷',
      Sharequestionnairewith:'分享给',
      SharingCompleted:'分享已完成',
      StudentAccountEditor:'成员账号编辑',
      Delete1:'删除班级',
      SendContent:'发送内容',
      SendaQaH:'发送问卷/通知给',
      SendQA:'发送问卷',
      SendNotic:'发送通知',
      CopySuccessfully:'复制成功',
      HasBeenSet:'已设置',
      NotSet:'未设置',
      EnterYourPasswordAgain:'再次确认密码',
      Passwordandsecondarypasswordcannotbeempty:'密码和二次密码不能为空',
      SendaMessage:'发送信息',
      s:'秒',
      PleaseEnterTheVerificationCode:'请输入验证码',
      Folders:'文件',
      GradeLevel:'年级',
      ClassTheme:'班级颜色',
      ManageFolders:'文件管理',
      EditFolders:'文件编辑',
      qsr:'请输入成员名称',
      Activateaccount:'激活账号',
      thatperson:'个人',
      editGroupVue:'编辑群组',
      icon:'图片',
      photoSelectTitle1: "共",
      photoSelectTitle2: "张相片",
      color: '颜色',
      familyMemberAccount: '家庭成员 & 账号',
      editStudents: '成员账号编辑',
      account:'账号',
      isolatedMessage: '群成员互不可见',
      groupOwner: '群主',
      MessageCannotBeEmpty:'消息不能为空',
      mediaFileMaxLimit:'图片已达到上限40张，请少量多次上传',
      InvalidPhoneNumber:'请输入正确的手机号码',
      DeleteComment:'是否删除此评论',

      tips: '提示',
      tipMessage1: '此操作将永久删除该消息, 是否继续?',
      tipMessage2: '是否删除此评论?',
      tipMessage3: '请输入分享人的ID',
      tipMessage4: '确定要删除此问卷?',
      tipMessage5: '是否撤回全部成员的问卷？',
      tipMessage6: '操作成功',
      tipMessage7: '上传成功',
      tipMessage8: '发送成功',
      tipMessage9: '已取消删除',
      failTxt: '翻译失败，请检查网络，点击重试。',
      all: '全部'
    },
};