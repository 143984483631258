
import Vue from "vue";
import VueI18n from 'vue-i18n'
import zn from "./zn"
import en from "./en"
import enLocale from 'element-ui/lib/locale/lang/en' //引入elementui语言包
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //引入ele语言包
Vue.use(VueI18n);   // 全局注册国际化包

var size = localStorage.getItem('size') || 'zn'
 
// 准备翻译的语言环境信息
// const i18n = new VueI18n({
//   locale: size,   
//   messages: {
//     "zn":zn,
//     "en":en
//   }
// }); 

const i18n = new VueI18n({
  locale: size,//将i18n的locale设置为你需要的语言
  messages: {
    // 'zn':{
    //         ...zhLocale,...zn
    // },
    // 'en':{ ...enLocale,...en}
    'zn':Object.assign(zn, zhLocale),
    'en':Object.assign(en, enLocale),
  }
})
 
export default i18n