import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import i18n from "./lang";
//注册路由
import router from "./router";

import VueClipBoard from "vue-clipboard2";

Vue.use(VueClipBoard);

//引入样式文件
import "@/assets/style/theme/index.css";
import "@/assets/common.css";
import "@/assets/icons/iconfont.css";

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
